import { template as template_e60fc5e5d81748fdbe11d81b3cbb444a } from "@ember/template-compiler";
const FKControlMenuContainer = template_e60fc5e5d81748fdbe11d81b3cbb444a(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
