import { template as template_d83e2cd6fa5a46cabc251ce3ef1e17a0 } from "@ember/template-compiler";
const WelcomeHeader = template_d83e2cd6fa5a46cabc251ce3ef1e17a0(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
